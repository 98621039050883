// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';


// $primaryColor: #BADA55;

// h1 { color:  $primaryColor; }

.btn-social {
  color: #fff !important;
}

.btn-google {
  background-color: #c32f10 !important;
}

.btn-facebook {
  background-color: #3b5998 !important;
}

.logo {
  background-image: url("/aia-logo.png");
  background-repeat: no-repeat;
  position: absolute;
  z-index: 100001;
}

.center-text {
  text-align: center;
}

.form-base {
  width: 100%;
  max-width: 330px;
  padding: '15px';
  margin: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebedef !important;
}
.breadcrumb {
  background-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
